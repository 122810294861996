<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title"><i class="far fa-piggy-bank text-primary"></i>{{ $t('deposit.1') }}</div>
		</div>
		<div class="section_right_inner">
			


			<div class="s_left">
				<!--s_left-->
				<div class="withdrawal_section01">
					<!--withdrawal_section01-->
					<p class="withdrawal_section01_t"><span>{{ $t('deposit.2') }}</span></p>
					<!--total_box end-->
					<div name="packagefrm">
						<div class="withdrawal_input_box">
							<!--withdrawal_input_box-->
							<table style="width:100%;">
								<tbody>
									<tr>
										<td>{{ $t('deposit.3') }}</td>
										<td>
											<select class="withdrawal_input01" v-model='coin' @change="ChangeCoin()">
												<option value='' style='display: none'>{{ $t('deposit.4') }}</option>
												<option :value='item.coin' style='' v-for="(item,index) in coin_list" :key="index">{{item.coin}}</option>
											</select>
										</td>
									</tr>
									<tr>
										<td>{{ $t('deposit.5') }}</td>
										<td>
											<div>
												<input type="text" name="addres" id="balance" class="withdrawal_input01" :value="balance" readonly="">
											</div>
											
										</td>
									</tr>
									<tr v-if="coin !=''">
										<td>{{$t('deposit.6')}}</td>
										<td>
											<p class="qrcode">
												<vue-qrcode :value="address" />
											</p>
											<input type="text" name="addres" id="recaddr" class="withdrawal_input01" :value="address" readonly="">
											<button type="button" id="next" class="btn-qrcode" onclick="copy_to_clipboard()">{{$t('deposit.7')}}</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<!--withdrawal_section01-->

				
			</div>
			
		</div>
		

		
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");
import VueQrcode from 'vue-qrcode'
	export default {
		components: {
			VueQrcode,
		},
		data(){
			return{
				address: '',
				balance : '',
				coin_list : [],
				coin : '',
			}
		},
	    mounted(){
			this.GetCoinInfo();
		},
		methods: {
			ChangeCoin(){
				const coin = this.coin;
				for (const el of this.coin_list) {
					if(coin ==el.coin){
						this.address = el.address
					}
				}
				this.GetWalletBalance()

			},
			GetWalletBalance(){
				const coin = this.coin;
				const body = {coin};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/deposit/GetWalletBalance`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								this.balance = body.balance;

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
				
			},
			CheckBalance(coin){

				let balance = '';
				for (const el of this.coin_list) {
					if(coin ==el.coin){
						balance = el.balance;
					}
				}



				return this.numberWithCommas(balance)
			},
			GetCoinInfo(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/deposit/GetCoinInfo`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.coin_list = body.list;
								this.coin = body.list[0].coin;
								this.ChangeCoin()
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			numberWithCommas(x) {
				var parts=x.toString().split(".");
				return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
			},
		}
	}
</script>
<style>
</style>